import BaseService from '@/services/BaseService'

class Hscodes extends BaseService {
  constructor() {
    super()
  }
  async getHscodes(opts = {}) {
    this.endPoint = 'api/v2/hscode'
    return this.get(opts)
  }
  async getCountryHscodes(countryId, opts = {}) {
    this.endPoint = `countries/${countryId}/hscodes`
    return this.get(opts)
  }

  async addHsCodes(payload, id) {
    this.endPoint = 'api/v2/hscode'
    if (id === ''){
      return this.post(payload)
    } else {
      return this.putOne(id, payload)
    }
  }

  async updateHscode(id, data={}){
    this.endPoint = `api/v2/hscode/${id}`
    return this.put(data).then(response => {
      return response.data
    })
  }
  
  async getHscodeById(id) {
    this.endPoint = `api/v2/hscode/${id}`
    return this.get()
  }

  async getOneHscodes(Id, opts = {}) {
    this.endPoint = 'api/v2/hscode'
    return this.getOne(Id, opts)
  }

  async deleteHscode(id) {
    this.endPoint = 'api/v2/hscode'
    return this.delete(id)
  }
}

export default new Hscodes()

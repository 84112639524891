import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import without from 'lodash/without'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'

// Base Page
import BasePage from '@/pages/Base'

// Services
import Rules from '@/services/Rules'
import HscodesService from '@/services/Hscodes'
import ProductService from '@/services/Products'
import CountryService from '@/services/Country'

@Component({})
export default class ProductRulesIndex extends BasePage {
  constructor() {
    super()
  }

  // HSCodes
  hscodes: any = []
  idHscode: any = null
  getHscodeLoading: boolean = false

  // Add New
  addNewProductRule: boolean = false
  editMode: boolean = false
  newData: any = {
    ruleName: '',
    hscodes: {
      selected: null,
      keyword: null
    },
    weight: {
      real: null,
      volumetric: null,
      unit: ''
    },
    methods: {
      items: [{ text: 'Use Real Weight', value: 'real' }, { text: 'Use Volumetric Weight', value: 'volumetric' }],
      selected: null
    },
    qty_cbm: null,
    dimensions: {
      width: null,
      length: null,
      height: null,
      unit: ''
    }
  }

  // Weight unit
  weightUnit: any = [
    {
      text: 'Kg',
      value: 'KG' 
    },
    {
      text: 'Gram',
      value: 'GR' 
    },
    {
      text: 'Pounds',
      value: 'LBS' 
    },
    {
      text: 'Ounce',
      value: 'OZ' 
    }
  ]

  // Dimension unit
  dimensionUnit: any = [
    {
      text: 'Meter',
      value: 'M' 
    },
    {
      text: 'Centimeter',
      value: 'CM' 
    },
    {
      text: 'Feet',
      value: 'FT' 
    },
    {
      text: 'Inch',
      value: 'INCH' 
    }
  ]

  // Edit Rule
  editedRule: string = ''

  productRulesTable: any = {
    tableHeaders: [
      {
        text: 'Rule Name',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Real Weight',
        align: 'right',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Volumetric Weight',
        align: 'right',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Implementation Methods',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'HS Code',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Quantity (PCS) / CBM',
        align: 'right',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Dimensions ( W x H x L )',
        align: 'center',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Action',
        align: 'center',
        sortable: false,
        class: 'action-table-width'
      }
    ],
    tableItems: [],
    tableTotalItems: 0,
    tableRowsPerPageItems: [5, 10, 15, 25, 50, 100],
    tablePagination: {
      sortBy: 'created_at',
      page: 1,
      rowsPerPage: 25,
      descending: true,
      totalItems: 0
    },
    tableLoading: false
  }

  // Products List
  showProducts: boolean = false
  selectedRule: any = null
  products: any = {
    items: [],
    selected: [],
    keyword: null,
    loading: false
  }
  searchDebounce: Function
  productListTable: any = {
    tableHeaders: [
      {
        text: 'Product ID',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Product Name',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Action',
        align: 'center',
        sortable: false,
        class: 'action-table-width'
      }
    ]
  }
  showDetachModal: boolean = false
  selectedDetach: any = null

  // Country
  countries: any = []
  countrySelected: any = null
  countryNameHint: string = 'Select country'
  getCountriesLoading: boolean = false

  async getCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data

      this.countries = []
      for (const country of responseData) {
        this.countries.push(country.attributes)
      }
      this.countries = sortBy(this.countries, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  async mounted() {
    // await this.getHscodes()
    await this.getCountries()
    await this.getProductRules()

    this.searchDebounce = debounce(this.getProducts, 500)
  }

  @Watch('products.keyword')
  onChanged() {
    this.searchDebounce()
  }
  @Watch('countrySelected')
  onCountrySelected() {
    if (this.countrySelected){
      this.getHscodes()
    } else {
      this.newData.hscodes.selected = null
      this.hscodes = []
      this.idHscode = null
    }
  }
  @Watch('newData.hscodes.keyword')
  onHscodeChange() {
    this.getHscodes()
  }

  async getHscodes() {
    try {
      this.getHscodeLoading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25, // Get all hscode up to current date of total 17 hscode
          sort: '-id',
          'filter[country_id][is]': this.countrySelected
        }
      }

      if (!isEmpty(this.newData.hscodes.keyword)) {
        const hscodeSplits = split(this.newData.hscodes.keyword, ' - ', 1)
        opts.params = {
          ...opts.params,
          'filter[code][like]': hscodeSplits[0]
        }
      } else {
        let filter = 'filter[code][like]'
        delete opts.params[filter]
      }

      const response = await HscodesService.getHscodes(opts)
      const responseData = response.data.data
      
      const includeCountry = map(response.data.included.country)

      this.hscodes = []
      for (const dataHscode of responseData) {
        const taxBM = find(dataHscode.attributes.taxes, tax => lowerCase(tax.label) === 'bm')
        const taxPPN = find(dataHscode.attributes.taxes, tax => lowerCase(tax.label) === 'ppn')
        const taxPPH = find(dataHscode.attributes.taxes, tax => lowerCase(tax.label) === 'pph')

        const countryName: any = find(includeCountry, (dataCountry: any) => dataCountry.attributes.id == dataHscode.attributes.country_id)

        const hscode = {
          ...dataHscode.attributes,
          displayText: `${dataHscode.attributes.code} - ${countryName.attributes.name}`,
          taxBM: taxBM !== undefined ? taxBM.value : '',
          taxPPN: taxPPN !== undefined ? taxPPN.value : '',
          taxPPH: taxPPH !== undefined ? taxPPH.value : ''
        }
        this.hscodes.push(hscode)
      }
      
      if (this.idHscode) {
        const hscodeSelected = find(this.hscodes, hscode => hscode.id === this.idHscode)
        this.newData.hscodes.selected = hscodeSelected
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getHscodeLoading = false
    }
  }

  async getProductRules() {
    try {
      this.productRulesTable.tableLoading = true

      const opts: any = {
        params: {
          'page[num]': this.productRulesTable.tablePagination.page,
          'page[limit]': this.productRulesTable.tablePagination.rowsPerPage,
          sort: (this.productRulesTable.tablePagination.descending ? '-' : '') + this.productRulesTable.tablePagination.sortBy,
          ...this.searchFilterParams,
          include: 'hscodes'
        }
      }

      const response = await Rules.getProductRules(opts)
      const responseData = response.data.data
      const responseProducts = response.data.included.products
      const responseHscodes = response.data.included.hscodes

      this.productRulesTable.tableItems = []
      for (const dataProductRule of responseData) {
        const dimensionWidth = find(dataProductRule.attributes.dimensions, dimension => lowerCase(dimension.label) === 'width')
        const dimensionLength = find(dataProductRule.attributes.dimensions, dimension => lowerCase(dimension.label) === 'length')
        const dimensionHeight = find(dataProductRule.attributes.dimensions, dimension => lowerCase(dimension.label) === 'height')

        const productRule = {
          ...dataProductRule.attributes,
          actionFab: false,
          products: [],
          dimensionWidth: dimensionWidth.value,
          dimensionLength: dimensionLength.value,
          dimensionHeight: dimensionHeight.value
        }

        // Hscodes
        if (dataProductRule.relationships.hscodes.length > 0) {
          forEach(responseHscodes, dataHscode => {
            if (Number(dataProductRule.relationships.hscodes[0].id) === dataHscode.attributes.id) {
              productRule.hscode = dataHscode.attributes
            }
          })

          const countryName = find(this.countries, country => country.id === productRule.hscode.country_id)
          productRule.hscodeDisplayText = `${productRule.hscode.code} - ${countryName.name}`
        }

        productRule.methodDisplayText =
          dataProductRule.attributes.implemented_weight === 'real' ? 'Using Real Weight' : 'Using Volumetric Weight'

        // Products List
        productRule.products = []
        if (!isEmpty(dataProductRule.relationships.products)) {
          for (const dataProduct of dataProductRule.relationships.products) {
            productRule.products.push({
              ...responseProducts[dataProduct.id].attributes,
              actionFab: false
            })
          }
        }

        this.productRulesTable.tableItems.push(productRule)
      }
      this.productRulesTable.tableTotalItems = responseData.length
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.productRulesTable.tableLoading = false
    }
  }

  async saveAddProductRule() {
    try {
      const valid = await this.$validator.validateAll('addNewProductRuleScope')
      if (valid) {
        const payload = {
          product_description: this.newData.ruleName,
          hscode_id: this.newData.hscodes.selected.id,
          real_weight: this.newData.weight.real,
          volumetric_weight: this.newData.weight.volumetric,
          weight_unit: this.newData.weight.unit,
          implemented_weight: this.newData.methods.selected,
          qty_cbm: this.newData.qty_cbm,
          dimensions: [
            {
              label: 'width',
              value: this.newData.dimensions.width
            },
            {
              label: 'length',
              value: this.newData.dimensions.length
            },
            {
              label: 'height',
              value: this.newData.dimensions.height
            }
          ],
          dimension_unit: this.newData.dimensions.unit
        }

        this.showLoading({ text: 'Saving...' })
        this.cancelModal()

        const response = await Rules.saveProductRule(payload, this.editMode ? this.editedRule : '')
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' +response.status),
          color: response.status === 200 ? 'green' : 'red',
          timeout: 1500
        })

        await this.getProductRules()
      } else {
        this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async getProducts() {
    try {
      this.products.loading = true
      if (isEmpty(this.products.selected)){
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 25,
            sort: '-created_at',
            'filter[title][like]': ''
          }
        }
        if (!isEmpty(this.products.keyword)) {
          var textFilter = 'filter[title][like]'
          opts.params[textFilter] = this.products.keyword
          // opts.params.searchJoin = 'and'
        } else {
          var textFilter = 'filter[title][like]'
          delete opts.params[textFilter]
          // delete opts.params.searchJoin
        }

        const response = await ProductService.getProductsLists(opts)
        const responseData = response.data

        // Check if the product already has rule, remove the product if true
        const responseRule = await Rules.getProductRules()
        const dataRulesProduct = map(responseRule.data.included.products, (dataRules) => {
          return dataRules.attributes.id
        })

        const itemProducts = filter(responseData, getProduct => !includes(dataRulesProduct, getProduct.attributes.id))

        this.products.items = []
        for (const dataProduct of itemProducts) {
          const product = {
            ...dataProduct.attributes
          }
          this.products.items.push(product)
        }
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.products.loading = false
    }
  }

  async applyProductRule() {
    try {
      if (!isEmpty(this.products.selected)) {
        const currentAttached = await Rules.getOneProductRules(this.selectedRule.id)
        this.showSnackbar({
          text: startCase(currentAttached.status === 200 ? 'Success' : 'Failed with HTTP error: ' +currentAttached.status),
          color: currentAttached.status === 200 ? 'green' : 'red',
          timeout: 1500
        })
        const payload = {
          product_id: map(this.products.selected, 'id'),
          action: map(currentAttached.data.included.products).length > 0 ? 'add' : 'attach'
        }

        this.showLoading({ text: 'Saving...' })
        this.cancelModalProducts()

        const response = await Rules.attachProductsToRule(this.selectedRule.id, payload)
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' +response.status),
          color: response.status === 200 ? 'green' : 'red',
          timeout: 1500
        })
        await this.getProductRules()
      } else {
        this.showSnackbar({ text: 'Please select at least one product', color: 'red', timeout: 1500 })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async detachProduct() {
    try {
      const payload = {
        product_id: [this.selectedDetach.id],
        action: 'detach'
      }

      this.showLoading({ text: 'Saving...' })
      this.showDetachModal = false

      const response = await Rules.detachProductsToRule(this.selectedRule.id, payload)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' +response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })

      this.getProductRules()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openAddModal() {
    this.editMode = false
    this.addNewProductRule = true
    this.countrySelected = 99 // Default country_id 99 for indonesia
  }

  openEditModal(props) {
    this.editMode = true
    this.editedRule = props.item.id
    this.countrySelected = !isEmpty(props.item.hscode) ? props.item.hscode.country_id : 99 // Default country id 99 for indonesia
    this.idHscode = !isEmpty(props.item.hscode) ? props.item.hscode.id : null
    this.newData = {
      ruleName: props.item.product_description,
      hscodes: {
        selected: null,
        keyword: null
      },
      weight: {
        real: props.item.real_weight,
        volumetric: props.item.volumetric_weight,
        unit: props.item.weight_unit
      },
      methods: {
        items: [{ text: 'Use Real Weight', value: 'real' }, { text: 'Use Volumetric Weight', value: 'volumetric' }],
        selected: props.item.implemented_weight
      },
      qty_cbm: props.item.qty_cbm
    }

    const dimensionWidth = find(props.item.dimensions, dimension => lowerCase(dimension.label) === 'width')
    const dimensionLength = find(props.item.dimensions, dimension => lowerCase(dimension.label) === 'length')
    const dimensionHeight = find(props.item.dimensions, dimension => lowerCase(dimension.label) === 'height')

    this.newData.dimensions = {
      width: dimensionWidth.value,
      height: dimensionHeight.value,
      length: dimensionLength.value,
      unit: props.item.dimension_unit
    }

    this.addNewProductRule = true
  }

  openProductsModal(props) {
    // if (isEmpty(this.products.items)) 
    this.products.items = []
    this.getProducts()
    this.selectedRule = props.item
    this.showProducts = true
  }

  openDetachModal(props, expandProps) {
    this.selectedRule = props.item
    this.selectedDetach = expandProps.item
    this.showDetachModal = true
  }

  cancelModal() {
    this.$validator.reset()
    this.idHscode = null
    this.hscodes = []
    this.countrySelected = null
    this.newData = {
      ruleName: '',
      hscodes: {
        selected: null,
        keyword: null
      },
      weight: {
        real: null,
        volumetric: null,
        unit: ''
      },
      methods: {
        items: [{ text: 'Use Real Weight', value: 'real' }, { text: 'Use Volumetric Weight', value: 'volumetric' }],
        selected: null
      },
      qty_cbm: null,
      dimensions: {
        width: null,
        length: null,
        height: null,
        unit: ''
      }
    }
    this.addNewProductRule = false
  }

  cancelModalProducts() {
    this.products.selected = null
    this.showProducts = false
  }

  cancelDetachModal() {
    this.showDetachModal = false
  }

  async searchFilterChanged(newData: any) {
    this.productRulesTable.tablePagination.page = 1
    this.searchFilterParams = newData
    await this.getProductRules()
  }

  isEmpty(variable) {
    return isEmpty(variable)
  }
}
